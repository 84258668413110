<template>
  <a-modal
    title="账号批量注册"
    width="1200px"
    v-model="visible"
    @ok="handleSubmit"
    @cancel="close"
    :confirmLoading="loading"
  >
    <a-table :columns="columns" :data-source="tableData" :pagination="false">
      <template slot="username" slot-scope="text, record">
        <a-input v-model="record.username" placeholder="请输入账号"> </a-input>
      </template>
      <template slot="location" slot-scope="text, record">
        <a-cascader
          :options="options"
          :fieldNames="{
            label: 'district',
            value: 'district',
            children: 'children',
          }"
          v-model="record.location"
          placeholder="请选择区域"
        />
      </template>
      <template slot="password" slot-scope="text, record">
        <a-input v-model="record.password" placeholder="请输入密码"> </a-input>
      </template>
      <!-- <template slot="code" slot-scope="text, record">
        <a-input v-model="record.code" placeholder="请输入服务码（选填）"> </a-input>
      </template> -->
      <template slot="type" slot-scope="text, record, index">
        <a-select
          style="width: 100%"
          @change="handleChangeType.call(null, $event, index)"
          placeholder="请选择人员类别"
          allowClear
          v-model="record.type"
        >
          <a-select-option v-for="item in personOption" :value="item.id" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <template slot="positionId" slot-scope="text, record, index">
        <a-select style="width: 100%" allowClear :placeholder="`请选择${getLabel(index)}`" v-model="record.positionId">
          <a-select-option v-for="item in getPositionOption(record.type)" :value="item.id" :key="item.id">
            {{ item.majorName }}
          </a-select-option>
        </a-select>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { getTree } from '@/api/location'
import { batchRegisterForApp } from '@/api/system'
import { employmentEmploypersonnelType, employmentEmployDictPosition } from '@/api/workDemand'
import reg from '@/utils/reg'

export default {
  name: 'AddUserModal',
  data () {
    return {
      visible: false,
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 50,
          customRender: (r, t, i) => i + 1
        },
        {
          title: '账号（必填）',
          dataIndex: 'username',
          width: 160,
          scopedSlots: { customRender: 'username' },
          key: 'username'
        },
        {
          title: '区域（必填）',
          dataIndex: 'location',
          width: 160,
          scopedSlots: { customRender: 'location' },
          key: 'location'
        },
        {
          title: '密码（必填）',
          dataIndex: 'password',
          width: 160,
          scopedSlots: { customRender: 'password' },
          key: 'password'
        },
        // {
        //   title: '服务码（选填）',
        //   dataIndex: 'code',
        //   width: 160,
        //   scopedSlots: { customRender: 'code' },
        //   key: 'code'
        // },
        {
          title: '人员类别（必填）',
          dataIndex: 'type',
          width: 160,
          scopedSlots: { customRender: 'type' },
          key: 'type'
        },
        {
          title: '工种/专业（必填）',
          dataIndex: 'positionId',
          width: 160,
          scopedSlots: { customRender: 'positionId' },
          key: 'positionId'
        }
      ],
      options: [],
      personOption: [],
      positionOption1: [],
      positionOption2: [],
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      getTree().then((res) => {
        this.options = res
      })
      employmentEmploypersonnelType() // 人员类别
        .then((res) => {
          this.personOption = res.data
        })
      employmentEmployDictPosition({ type: 1 }).then((res) => {
        this.positionOption1 = res.data
      })
      employmentEmployDictPosition({ type: 2 }).then((res) => {
        this.positionOption2 = res.data
      })
    },
    open (tableData) {
      const data = tableData.map((item) => ({
        ...item,
        location: item.location.split('-')
      }))
      this.$set(this, 'tableData', data)
      this.visible = true
    },
    close () {
      this.visible = false
      this.tableData = []
    },
    handleResultLocation (location) {
      if (location.length > 3) return false
      return this.handleCallResult(location, this.options)
    },
    handleCallResult (location, areaList = []) {
      if (!location.length && areaList.length) return false
      if (!location.length) {
        return true
      }
      const str = location[0]
      const data = areaList.find((item) => item.district === str)
      if (data) {
        return this.handleCallResult(location.slice(1), data.children)
      } else {
        return false
      }
    },
    async handleResult () {
      const resMap = this.tableData.reduce((map, item, index) => {
        const { username, location = [], password, type, positionId } = item
        if (!username || !location.length || !password || !type || !positionId) {
          map.set(index + 1, '请填写完整')
        }
        const result = this.handleResultLocation(location)
        if (!result) {
          map.set(index + 1, '请选择正确的区域')
        }
        if (reg.password.pattern.test(password) === false) {
          map.set(index + 1, reg.password.msg)
        }
        if (reg.phone.pattern.test(username) === false) {
          map.set(index + 1, reg.phone.msg)
        }
        return map
      }, new Map())
      if (resMap.size > 0) {
        const arr = []
        resMap.entries().forEach(([key, value]) => {
          arr.push(`第【${key}】行【${value}】`)
        })
        this.$message.error({
          content: (
            <div>
              {arr.map((text) => {
                return <p>{text}</p>
              })}
            </div>
          )
        })
      }
      return resMap.size === 0
    },
    async handleSubmit () {
      const result = await this.handleResult()
      if (result) {
        this.loading = true
        const data = this.tableData.map((item) => ({
          ...item,
          code: '',
          location: item.location.join('-'),
          enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
          labelAddCmd: {
            type: item.type,
            positionId: item.positionId
          }
        }))
        batchRegisterForApp(data)
          .then((res) => {
            this.$message.success('账号新增成功')
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleChangeType (value, index) {
      this.$set(this.tableData[index], 'positionId', undefined)
    },
    getLabel (index) {
      console.log(index)
      const data = this.tableData[index].type
      return data === '2' ? '专业' : '工种'
    },
    getPositionOption (type) {
      if (!type) return []
      return this[`positionOption${type}`]
    }
  }
}
</script>
